'use client'

import React from 'react'
import {
    Button,
    DatePicker,
    Radio,
    Upload,
    Form,
    Input,
    InputNumber,
    Select,
    message,
    AutoComplete
} from 'antd';
import type { UploadFile } from 'antd';
import { ConfigProvider } from "antd";
import esES from "antd/es/locale/es_ES";
import { FaPlus } from "react-icons/fa6";
import { useState } from 'react';
import { EyeInvisibleOutlined, EyeTwoTone, UploadOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { Card } from "antd";
import { MdOutlineDelete } from "react-icons/md";
import { MdErrorOutline } from "react-icons/md";
import { PiFileCodeDuotone, PiMicrosoftWordLogoFill } from "react-icons/pi";
import { PiMicrosoftExcelLogoFill } from "react-icons/pi";
import { PiMicrosoftPowerpointLogoFill } from "react-icons/pi";
import { PiFilePdf } from "react-icons/pi";



interface AntInputType {
    title?: string;
    name: string;
    required: boolean;
    placeholder?: string;
    type: string;
    formName: string;
    children?: React.ReactNode;
    hideFeedback?: boolean;
    disabled?: boolean;
    option?: string;
    select_options?: any;
    parent_value?: string;
    onChangeFunc?: (value: any) => void;
    default_value?: string
    id?: string
}

const AntInput = (
    {
        title,
        name,
        required,
        placeholder,
        type,
        formName,
        children,
        hideFeedback,
        disabled,
        option,
        select_options,
        parent_value,
        onChangeFunc,
        default_value,
        id,
    }: AntInputType) => {


    if (type == "busqueda") {
        const [inputValue, setInputValue] = useState('')
        const handleChange = (value: any, prevValue: any, prevValues: any) => {
            let ivalue = value
            // ivalue = ivalue.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            ivalue = limpiarString(ivalue)
            ivalue = ivalue.replace(/[^a-zA-ZñÑ0-9\s]/g, '');
            // ivalue = ivalue.toUpperCase()
            return ivalue
        }

        const limpiarString = (texto: string) => {
            // Remover caracteres especiales
            texto = texto.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
            // Reemplazar caracteres acentuados por su variante sin acento
            const acentos: any = {
                'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u',
                'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U',
            };
            texto = texto.replace(/[áéíóúÁÉÍÓÚ]/g, match => acentos[match]);        
            return texto;
        }

        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                    // label={title}
                    name={name}
                    rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'string', message: 'Datos inválidos' }]}
                    required={required}
                    validateTrigger='onBlur'
                    style={{ marginBottom: required ? '24px' : '0' }}
                    normalize={handleChange}
                // validateDebounce={500}
                >
                    <Input placeholder={placeholder} disabled={disabled} id={id} className='uppercase' />
                </Form.Item>
            </div>
        )
    }

    if (type == "text") {
        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                    // label={title}
                    name={name}
                    rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'string', message: 'Datos inválidos' }]}
                    required={required}
                    validateTrigger='onBlur'
                    style={{ marginBottom: required ? '24px' : '0' }}
                // validateDebounce={500}
                >
                    <Input placeholder={placeholder} disabled={disabled} id={id} />
                </Form.Item>
            </div>
        )
    }

    if (type == "date") {
        return (
            <div className='flex-grow'>
                <ConfigProvider locale={esES}>
                    <Form.Item
                        hasFeedback={hideFeedback ? false : true}
                        // label={<label className='font-medium text-sm'>{title}</label>} 
                        label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                        name={name}
                        rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'date', message: 'Datos inválidos' }]}
                        // rules={[{ required: required, message: 'Este campo es obligatorio' } ]}
                        // rules= {[{ type: 'object' as const, required: true, message: 'Please select time!' }]}
                        validateTrigger='onBlur'
                    // validateDebounce={500}
                    >
                        <DatePicker placeholder={placeholder} className='w-full' disabled={disabled} id={id}/>
                    </Form.Item>
                </ConfigProvider>
            </div>
        )
    }

    if (type == "country") {
        const filterOption = (input: string, option?: { label: string; value: string }) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

        const onChange = (value: string) => {
            console.log(`selected ${value}`);
        };

        const countries = [
            { value: 'mexico', label: 'México', countryCode: 'MX' },
            { value: 'afghanistan', label: 'Afganistán', countryCode: 'AF' },
            { value: 'albania', label: 'Albania', countryCode: 'AL' },
            { value: 'algeria', label: 'Argelia', countryCode: 'DZ' },
            { value: 'andorra', label: 'Andorra', countryCode: 'AD' },
            { value: 'angola', label: 'Angola', countryCode: 'AO' },
            { value: 'antigua_and_barbuda', label: 'Antigua y Barbuda', countryCode: 'AG' },
            { value: 'argentina', label: 'Argentina', countryCode: 'AR' },
            { value: 'armenia', label: 'Armenia', countryCode: 'AM' },
            { value: 'australia', label: 'Australia', countryCode: 'AU' },
            { value: 'austria', label: 'Austria', countryCode: 'AT' },
            { value: 'azerbaijan', label: 'Azerbaiyán', countryCode: 'AZ' },
            { value: 'bahamas', label: 'Bahamas', countryCode: 'BS' },
            { value: 'bahrain', label: 'Baréin', countryCode: 'BH' },
            { value: 'bangladesh', label: 'Bangladesh', countryCode: 'BD' },
            { value: 'barbados', label: 'Barbados', countryCode: 'BB' },
            { value: 'belarus', label: 'Bielorrusia', countryCode: 'BY' },
            { value: 'belgium', label: 'Bélgica', countryCode: 'BE' },
            { value: 'belize', label: 'Belice', countryCode: 'BZ' },
            { value: 'benin', label: 'Benín', countryCode: 'BJ' },
            { value: 'bhutan', label: 'Bután', countryCode: 'BT' },
            { value: 'bolivia', label: 'Bolivia', countryCode: 'BO' },
            { value: 'bosnia_and_herzegovina', label: 'Bosnia y Herzegovina', countryCode: 'BA' },
            { value: 'botswana', label: 'Botsuana', countryCode: 'BW' },
            { value: 'brazil', label: 'Brasil', countryCode: 'BR' },
            { value: 'brunei', label: 'Brunéi', countryCode: 'BN' },
            { value: 'bulgaria', label: 'Bulgaria', countryCode: 'BG' },
            { value: 'burkina_faso', label: 'Burkina Faso', countryCode: 'BF' },
            { value: 'burundi', label: 'Burundi', countryCode: 'BI' },
            { value: 'cabo_verde', label: 'Cabo Verde', countryCode: 'CV' },
            { value: 'cambodia', label: 'Camboya', countryCode: 'KH' },
            { value: 'cameroon', label: 'Camerún', countryCode: 'CM' },
            { value: 'canada', label: 'Canadá', countryCode: 'CA' },
            { value: 'central_african_republic', label: 'República Centroafricana', countryCode: 'CF' },
            { value: 'chad', label: 'Chad', countryCode: 'TD' },
            { value: 'chile', label: 'Chile', countryCode: 'CL' },
            { value: 'china', label: 'China', countryCode: 'CN' },
            { value: 'colombia', label: 'Colombia', countryCode: 'CO' },
            { value: 'comoros', label: 'Comoras', countryCode: 'KM' },
            { value: 'congo_democratic_republic', label: 'República Democrática del Congo', countryCode: 'CD' },
            { value: 'congo_republic', label: 'República del Congo', countryCode: 'CG' },
            { value: 'costa_rica', label: 'Costa Rica', countryCode: 'CR' },
            { value: 'croatia', label: 'Croacia', countryCode: 'HR' },
            { value: 'cuba', label: 'Cuba', countryCode: 'CU' },
            { value: 'cyprus', label: 'Chipre', countryCode: 'CY' },
            { value: 'czech_republic', label: 'República Checa', countryCode: 'CZ' },
            { value: 'denmark', label: 'Dinamarca', countryCode: 'DK' },
            { value: 'djibouti', label: 'Yibuti', countryCode: 'DJ' },
            { value: 'dominica', label: 'Dominica', countryCode: 'DM' },
            { value: 'dominican_republic', label: 'República Dominicana', countryCode: 'DO' },
            { value: 'ecuador', label: 'Ecuador', countryCode: 'EC' },
            { value: 'egypt', label: 'Egipto', countryCode: 'EG' },
            { value: 'el_salvador', label: 'El Salvador', countryCode: 'SV' },
            { value: 'equatorial_guinea', label: 'Guinea Ecuatorial', countryCode: 'GQ' },
            { value: 'eritrea', label: 'Eritrea', countryCode: 'ER' },
            { value: 'estonia', label: 'Estonia', countryCode: 'EE' },
            { value: 'eswatini', label: 'Esuatini', countryCode: 'SZ' },
            { value: 'ethiopia', label: 'Etiopía', countryCode: 'ET' },
            { value: 'fiji', label: 'Fiyi', countryCode: 'FJ' },
            { value: 'finland', label: 'Finlandia', countryCode: 'FI' },
            { value: 'france', label: 'Francia', countryCode: 'FR' },
            { value: 'gabon', label: 'Gabón', countryCode: 'GA' },
            { value: 'gambia', label: 'Gambia', countryCode: 'GM' },
            { value: 'georgia', label: 'Georgia', countryCode: 'GE' },
            { value: 'germany', label: 'Alemania', countryCode: 'DE' },
            { value: 'ghana', label: 'Ghana', countryCode: 'GH' },
            { value: 'greece', label: 'Grecia', countryCode: 'GR' },
            { value: 'grenada', label: 'Granada', countryCode: 'GD' },
            { value: 'guatemala', label: 'Guatemala', countryCode: 'GT' },
            { value: 'guinea', label: 'Guinea', countryCode: 'GN' },
            { value: 'guinea_bissau', label: 'Guinea-Bisáu', countryCode: 'GW' },
            { value: 'guyana', label: 'Guyana', countryCode: 'GY' },
            { value: 'haiti', label: 'Haití', countryCode: 'HT' },
            { value: 'honduras', label: 'Honduras', countryCode: 'HN' },
            { value: 'hungary', label: 'Hungría', countryCode: 'HU' },
            { value: 'iceland', label: 'Islandia', countryCode: 'IS' },
            { value: 'india', label: 'India', countryCode: 'IN' },
            { value: 'indonesia', label: 'Indonesia', countryCode: 'ID' },
            { value: 'iran', label: 'Irán', countryCode: 'IR' },
            { value: 'iraq', label: 'Irak', countryCode: 'IQ' },
            { value: 'ireland', label: 'Irlanda', countryCode: 'IE' },
            { value: 'israel', label: 'Israel', countryCode: 'IL' },
            { value: 'italy', label: 'Italia', countryCode: 'IT' },
            { value: 'jamaica', label: 'Jamaica', countryCode: 'JM' },
            { value: 'japan', label: 'Japón', countryCode: 'JP' },
            { value: 'jordan', label: 'Jordania', countryCode: 'JO' },
            { value: 'kazakhstan', label: 'Kazajistán', countryCode: 'KZ' },
            { value: 'kenya', label: 'Kenia', countryCode: 'KE' },
            { value: 'kiribati', label: 'Kiribati', countryCode: 'KI' },
            { value: 'north_korea', label: 'Corea del Norte', countryCode: 'KP' },
            { value: 'south_korea', label: 'Corea del Sur', countryCode: 'KR' },
            { value: 'kuwait', label: 'Kuwait', countryCode: 'KW' },
            { value: 'kyrgyzstan', label: 'Kirguistán', countryCode: 'KG' },
            { value: 'laos', label: 'Laos', countryCode: 'LA' },
            { value: 'latvia', label: 'Letonia', countryCode: 'LV' },
            { value: 'lebanon', label: 'Líbano', countryCode: 'LB' },
            { value: 'lesotho', label: 'Lesoto', countryCode: 'LS' },
            { value: 'liberia', label: 'Liberia', countryCode: 'LR' },
            { value: 'libya', label: 'Libia', countryCode: 'LY' },
            { value: 'liechtenstein', label: 'Liechtenstein', countryCode: 'LI' },
            { value: 'lithuania', label: 'Lituania', countryCode: 'LT' },
            { value: 'luxembourg', label: 'Luxemburgo', countryCode: 'LU' },
            { value: 'madagascar', label: 'Madagascar', countryCode: 'MG' },
            { value: 'malawi', label: 'Malaui', countryCode: 'MW' },
            { value: 'malaysia', label: 'Malasia', countryCode: 'MY' },
            { value: 'maldives', label: 'Maldivas', countryCode: 'MV' },
            { value: 'mali', label: 'Malí', countryCode: 'ML' },
            { value: 'malta', label: 'Malta', countryCode: 'MT' },
            { value: 'marshall_islands', label: 'Islas Marshall', countryCode: 'MH' },
            { value: 'mauritania', label: 'Mauritania', countryCode: 'MR' },
            { value: 'mauritius', label: 'Mauricio', countryCode: 'MU' },
            // { value: 'mexico', label: 'México', countryCode: 'MX' },
            { value: 'micronesia', label: 'Micronesia', countryCode: 'FM' },
            { value: 'moldova', label: 'Moldavia', countryCode: 'MD' },
            { value: 'monaco', label: 'Mónaco', countryCode: 'MC' },
            { value: 'mongolia', label: 'Mongolia', countryCode: 'MN' },
            { value: 'montenegro', label: 'Montenegro', countryCode: 'ME' },
            { value: 'morocco', label: 'Marruecos', countryCode: 'MA' },
            { value: 'mozambique', label: 'Mozambique', countryCode: 'MZ' },
            { value: 'myanmar', label: 'Myanmar', countryCode: 'MM' },
            { value: 'namibia', label: 'Namibia', countryCode: 'NA' },
            { value: 'nauru', label: 'Nauru', countryCode: 'NR' },
            { value: 'nepal', label: 'Nepal', countryCode: 'NP' },
            { value: 'netherlands', label: 'Países Bajos', countryCode: 'NL' },
            { value: 'new_zealand', label: 'Nueva Zelanda', countryCode: 'NZ' },
            { value: 'nicaragua', label: 'Nicaragua', countryCode: 'NI' },
            { value: 'niger', label: 'Níger', countryCode: 'NE' },
            { value: 'nigeria', label: 'Nigeria', countryCode: 'NG' },
            { value: 'north_macedonia', label: 'Macedonia del Norte', countryCode: 'MK' },
            { value: 'norway', label: 'Noruega', countryCode: 'NO' },
            { value: 'oman', label: 'Omán', countryCode: 'OM' },
            { value: 'pakistan', label: 'Pakistán', countryCode: 'PK' },
            { value: 'palau', label: 'Palaos', countryCode: 'PW' },
            { value: 'palestine', label: 'Palestina', countryCode: 'PS' },
            { value: 'panama', label: 'Panamá', countryCode: 'PA' },
            { value: 'papua_new_guinea', label: 'Papúa Nueva Guinea', countryCode: 'PG' },
            { value: 'paraguay', label: 'Paraguay', countryCode: 'PY' },
            { value: 'peru', label: 'Perú', countryCode: 'PE' },
            { value: 'philippines', label: 'Filipinas', countryCode: 'PH' },
            { value: 'poland', label: 'Polonia', countryCode: 'PL' },
            { value: 'portugal', label: 'Portugal', countryCode: 'PT' },
            { value: 'qatar', label: 'Catar', countryCode: 'QA' },
            { value: 'romania', label: 'Rumania', countryCode: 'RO' },
            { value: 'russia', label: 'Rusia', countryCode: 'RU' },
            { value: 'rwanda', label: 'Ruanda', countryCode: 'RW' },
            { value: 'saint_kitts_and_nevis', label: 'San Cristóbal y Nieves', countryCode: 'KN' },
            { value: 'saint_lucia', label: 'Santa Lucía', countryCode: 'LC' },
            { value: 'saint_vincent_and_the_grenadines', label: 'San Vicente y las Granadinas', countryCode: 'VC' },
            { value: 'samoa', label: 'Samoa', countryCode: 'WS' },
            { value: 'san_marino', label: 'San Marino', countryCode: 'SM' },
            { value: 'sao_tome_and_principe', label: 'Santo Tomé y Príncipe', countryCode: 'ST' },
            { value: 'saudi_arabia', label: 'Arabia Saudita', countryCode: 'SA' },
            { value: 'senegal', label: 'Senegal', countryCode: 'SN' },
            { value: 'serbia', label: 'Serbia', countryCode: 'RS' },
            { value: 'seychelles', label: 'Seychelles', countryCode: 'SC' },
            { value: 'sierra_leone', label: 'Sierra Leona', countryCode: 'SL' },
            { value: 'singapore', label: 'Singapur', countryCode: 'SG' },
            { value: 'slovakia', label: 'Eslovaquia', countryCode: 'SK' },
            { value: 'slovenia', label: 'Eslovenia', countryCode: 'SI' },
            { value: 'solomon_islands', label: 'Islas Salomón', countryCode: 'SB' },
            { value: 'somalia', label: 'Somalia', countryCode: 'SO' },
            { value: 'south_africa', label: 'Sudáfrica', countryCode: 'ZA' },
            { value: 'south_sudan', label: 'Sudán del Sur', countryCode: 'SS' },
            { value: 'spain', label: 'España', countryCode: 'ES' },
            { value: 'sri_lanka', label: 'Sri Lanka', countryCode: 'LK' },
            { value: 'sudan', label: 'Sudán', countryCode: 'SD' },
            { value: 'suriname', label: 'Surinam', countryCode: 'SR' },
            { value: 'sweden', label: 'Suecia', countryCode: 'SE' },
            { value: 'switzerland', label: 'Suiza', countryCode: 'CH' },
            { value: 'syria', label: 'Siria', countryCode: 'SY' },
            { value: 'taiwan', label: 'Taiwán', countryCode: 'TW' },
            { value: 'tajikistan', label: 'Tayikistán', countryCode: 'TJ' },
            { value: 'tanzania', label: 'Tanzania', countryCode: 'TZ' },
            { value: 'thailand', label: 'Tailandia', countryCode: 'TH' },
            { value: 'timor_leste', label: 'Timor-Leste', countryCode: 'TL' },
            { value: 'togo', label: 'Togo', countryCode: 'TG' },
            { value: 'tonga', label: 'Tonga', countryCode: 'TO' },
            { value: 'trinidad_and_tobago', label: 'Trinidad y Tobago', countryCode: 'TT' },
            { value: 'tunisia', label: 'Túnez', countryCode: 'TN' },
            { value: 'turkey', label: 'Turquía', countryCode: 'TR' },
            { value: 'turkmenistan', label: 'Turkmenistán', countryCode: 'TM' },
            { value: 'tuvalu', label: 'Tuvalu', countryCode: 'TV' },
            { value: 'uganda', label: 'Uganda', countryCode: 'UG' },
            { value: 'ukraine', label: 'Ucrania', countryCode: 'UA' },
            { value: 'united_arab_emirates', label: 'Emiratos Árabes Unidos', countryCode: 'AE' },
            { value: 'united_kingdom', label: 'Reino Unido', countryCode: 'GB' },
            { value: 'united_states', label: 'Estados Unidos', countryCode: 'US' },
            { value: 'uruguay', label: 'Uruguay', countryCode: 'UY' },
            { value: 'uzbekistan', label: 'Uzbekistán', countryCode: 'UZ' },
            { value: 'vanuatu', label: 'Vanuatu', countryCode: 'VU' },
            { value: 'vatican_city', label: 'Ciudad del Vaticano', countryCode: 'VA' },
            { value: 'venezuela', label: 'Venezuela', countryCode: 'VE' },
            { value: 'vietnam', label: 'Vietnam', countryCode: 'VN' },
            { value: 'yemen', label: 'Yemen', countryCode: 'YE' },
            { value: 'zambia', label: 'Zambia', countryCode: 'ZM' },
            { value: 'zimbabwe', label: 'Zimbabue', countryCode: 'ZW' }
        ];

        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    // label={<label className='font-medium text-sm'>{title}</label>} 
                    label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                    name={name}
                    rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'string', message: 'Datos inválidos' }]}
                    // rules={[{ required: required, message: 'Este campo es obligatorio' } ]}
                    // rules= {[{ type: 'object' as const, required: true, message: 'Please select time!' }]}
                    validateTrigger='onBlur'
                // initialValue={'mexico'}
                // validateDebounce={500}
                >
                    <Select
                        showSearch={true}
                        className='w-full'
                        placeholder={placeholder}
                        optionFilterProp="children"
                        onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={filterOption}
                        options={countries}
                        disabled={disabled}
                        id={id}
                    // defaultValue={'mexico'}
                    />
                </Form.Item>
            </div>
        )
    }
    if (type == "stateOld") {
        const filterOption = (input: string, option?: { label: string; value: string }) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

        const onChange = (value: string) => {
            console.log(`selected ${value}`);
        };

        const estados = [
            { value: "aguascalientes", label: "Aguascalientes" },
            { value: "baja_california", label: "Baja California" },
            { value: "baja_california_sur", label: "Baja California Sur" },
            { value: "campeche", label: "Campeche" },
            { value: "chiapas", label: "Chiapas" },
            { value: "chihuahua", label: "Chihuahua" },
            { value: "ciudad_de_mexico", label: "Ciudad de México" },
            { value: "coahuila", label: "Coahuila" },
            { value: "colima", label: "Colima" },
            { value: "durango", label: "Durango" },
            { value: "estado_de_mexico", label: "Estado de México" },
            { value: "guanajuato", label: "Guanajuato" },
            { value: "guerrero", label: "Guerrero" },
            { value: "hidalgo", label: "Hidalgo" },
            { value: "jalisco", label: "Jalisco" },
            { value: "michoacan", label: "Michoacán" },
            { value: "morelos", label: "Morelos" },
            { value: "nayarit", label: "Nayarit" },
            { value: "nuevo_leon", label: "Nuevo León" },
            { value: "oaxaca", label: "Oaxaca" },
            { value: "puebla", label: "Puebla" },
            { value: "queretaro", label: "Querétaro" },
            { value: "quintana_roo", label: "Quintana Roo" },
            { value: "san_luis_potosi", label: "San Luis Potosí" },
            { value: "sinaloa", label: "Sinaloa" },
            { value: "sonora", label: "Sonora" },
            { value: "tabasco", label: "Tabasco" },
            { value: "tamaulipas", label: "Tamaulipas" },
            { value: "tlaxcala", label: "Tlaxcala" },
            { value: "veracruz", label: "Veracruz" },
            { value: "yucatan", label: "Yucatán" },
            { value: "zacatecas", label: "Zacatecas" }
        ];

        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                    name={name}
                    rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'string', message: 'Datos inválidos' }]}
                    validateTrigger='onBlur'
                >
                    <Select
                        showSearch={true}
                        className='w-full'
                        placeholder={placeholder}
                        optionFilterProp="children"
                        onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={filterOption}
                        options={estados}
                        disabled={disabled}
                        id={id}
                    // defaultValue={'mexico'}
                    />
                </Form.Item>
            </div>
        )
    }
    if (type == "state") {
        const filterOption = (input: string, option?: { label: string; value: string }) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

        const onChange = (value: string) => {
            console.log(`selected ${value}`);
        };

        const estados = [
            { value: "aguascalientes", label: "Aguascalientes" },
            { value: "baja_california", label: "Baja California" },
            { value: "baja_california_sur", label: "Baja California Sur" },
            { value: "campeche", label: "Campeche" },
            { value: "chiapas", label: "Chiapas" },
            { value: "chihuahua", label: "Chihuahua" },
            { value: "ciudad_de_mexico", label: "Ciudad de México" },
            { value: "coahuila", label: "Coahuila" },
            { value: "colima", label: "Colima" },
            { value: "durango", label: "Durango" },
            { value: "estado_de_mexico", label: "Estado de México" },
            { value: "guanajuato", label: "Guanajuato" },
            { value: "guerrero", label: "Guerrero" },
            { value: "hidalgo", label: "Hidalgo" },
            { value: "jalisco", label: "Jalisco" },
            { value: "michoacan", label: "Michoacán" },
            { value: "morelos", label: "Morelos" },
            { value: "nayarit", label: "Nayarit" },
            { value: "nuevo_leon", label: "Nuevo León" },
            { value: "oaxaca", label: "Oaxaca" },
            { value: "puebla", label: "Puebla" },
            { value: "queretaro", label: "Querétaro" },
            { value: "quintana_roo", label: "Quintana Roo" },
            { value: "san_luis_potosi", label: "San Luis Potosí" },
            { value: "sinaloa", label: "Sinaloa" },
            { value: "sonora", label: "Sonora" },
            { value: "tabasco", label: "Tabasco" },
            { value: "tamaulipas", label: "Tamaulipas" },
            { value: "tlaxcala", label: "Tlaxcala" },
            { value: "veracruz", label: "Veracruz" },
            { value: "yucatan", label: "Yucatán" },
            { value: "zacatecas", label: "Zacatecas" }
        ];

        const [options, setOptions] = useState<{ value: string }[]>([]);

        const handleSearch = (value: string) => {
            const filteredOptions = estados
                .filter(estado => estado.label.toLowerCase().includes(value.toLowerCase()))
                .map(estado => ({ value: estado.label }));

            setOptions(filteredOptions);
        };

        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                    name={name}
                    rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'string', message: 'Datos inválidos' }]}
                    validateTrigger='onBlur'
                >
                    {/* <Select
                        showSearch={true}
                        className='w-full'
                        placeholder={placeholder}
                        optionFilterProp="children"
                        onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={filterOption}
                        options={estados}
                        disabled={disabled}
                    // defaultValue={'mexico'}
                    /> */}
                    <AutoComplete
                        options={options}
                        className='w-full'
                        // style={{ width: 200 }}
                        // onSelect={onSelect}
                        // onChange={onChange}
                        onSearch={handleSearch}
                        placeholder={placeholder}
                        disabled={disabled}
                        id={id}
                    />
                </Form.Item>
            </div>
        )
    }

    if (type == "email") {
        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    // label={<label className='font-medium text-sm'>{title}</label>} 
                    label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                    name={name}
                    rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'email', message: 'Correo inválido' }]}
                    required={required}
                    validateTrigger='onBlur'
                // validateDebounce={500}
                >
                    <Input placeholder={placeholder} disabled={disabled} id={id} />
                </Form.Item>
            </div>
        )
    }

    if (type == "radio") {

        // console.log('option: ', option);
        const [radioValue, setRadioValue] = useState(option ?? 'no')

        const handleChange = (e: any) => {
            // console.log(e.target.value)
            setRadioValue(e.target.value)
        }


        return (
            <div className='flex flex-col flex-grow'>
                <div className='flex-grow'>
                    <Form.Item
                        hasFeedback={hideFeedback ? false : true}
                        // label={<label className='font-medium text-sm'>{title}</label>} 
                        label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                        name={name}
                        rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'string', message: 'Datos Inválidos' }]}
                        required={required}
                        validateTrigger='onBlur'
                        id={id}
                    // initialValue={'no'}
                    // validateDebounce={500}
                    >
                        <Radio.Group onChange={handleChange} disabled={disabled}>
                            <Radio value="si"> Si </Radio>
                            <Radio value="no"> No </Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                {radioValue == 'si' && children}
            </div>
        )
    }
    if (type == "file") {

        const [fileList, setFileList] = useState<UploadFile[]>([]);
        const [uploading, setUploading] = useState(false);

        const { Dragger } = Upload;
        const normFile = (e: any) => {
            if (Array.isArray(e)) {
                return e;
            }
            return e?.fileList;
        };
        // const props: UploadProps = {
        //     name: 'file',
        //     maxCount: 1,
        //     listType: 'picture',
        //     // previewFile(file) {
        //     //     console.log('Your upload file:', file);
        //     //     // Your process logic. Here we just mock to the same file
        //     //     return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
        //     //       method: 'POST',
        //     //       body: file,
        //     //     })
        //     //       .then((res) => res.json())
        //     //       .then(({ thumbnail }) => thumbnail);
        //     //   },
        //     action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        //     onChange(info) {
        //         const { status } = info.file;
        //         if (status !== 'uploading') {
        //             console.log(info.file, info.fileList);
        //         }
        //         // if (status === 'done') {
        //         //     message.success(`${info.file.name} file uploaded successfully.`);
        //         // } else if (status === 'error') {
        //         //     message.error(`${info.file.name} file upload failed.`);
        //         // }
        //     },
        //     onDrop(e) {
        //         // console.log('Dropped files', e.dataTransfer.files);
        //     },
        // };
        // const props: UploadProps = {
        //     onRemove: (file) => {
        //       const index = fileList.indexOf(file);
        //       const newFileList = fileList.slice();
        //       newFileList.splice(index, 1);
        //       setFileList(newFileList);
        //     },
        //     onChange: ({ fileList: newFileList }) => {
        //         console.log('newFileList: ', newFileList);
        //     },
        //     beforeUpload: (file) => {
        //       setFileList([...fileList, file]);

        //       return false;
        //     },
        //     fileList,
        //   };

        // const handleUpload = async ({ file, onSuccess, onError }: any) => {
        //     if(file.size > 75 * 1024){
        //         message.error('El archivo es demasiado grande');
        //         console.log((onError()))
        //         // onError((event: any, body: any) => {
        //         //     console.log('error: ', event, body)
        //         // });
        //     } else {
        //         onSuccess('ok')
        //     }
        // };

        const [preview, setPreview] = useState({
            isOpen: false,
            seeImage: '',
            setTitle: ''
        });

        // const customItemRender = (originNode: React.ReactElement, file: UploadFile, fileList: UploadFile[], actions: any) => {
        //     // console.log(originNode, file)
        //     console.log('actions: ', actions);
        //     actions.preview();
        //     return (
        //         <div>
        //             {originNode}
        //             {file.status === 'error' && <div onClick={() => actions.remove(file)} style={{ color: 'red' }}>{file.response}{actions.preview()}</div>}
        //         </div>
        //     );
        // };
        const customItemRender = (originNode: React.ReactElement, file: any, fileList: UploadFile[], { preview, remove }: any) => {
            // console.log('file: ', file);
            file.thumbUrl = URL.createObjectURL(file.originFileObj as RcFile)
            const { Meta } = Card;
            const fileType = file.type;
            let fileTypeIcon = null;
            if (fileType.startsWith('image/')) {
                fileTypeIcon = <img alt="example" src={file.thumbUrl} className='w-52 h-28 object-cover object-top rounded-md' />
            }
            if (fileType === 'application/pdf') {
                fileTypeIcon = <PiFilePdf className='text-6xl' />
            }
            if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                fileTypeIcon = <PiMicrosoftWordLogoFill className='text-6xl' />
            }
            if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                fileTypeIcon = <PiMicrosoftExcelLogoFill className='text-6xl' />
            }
            if (fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
                fileTypeIcon = <PiMicrosoftPowerpointLogoFill className='text-6xl' />
            }
            if (fileType === 'application/xml' || fileType === 'text/xml') {
                fileTypeIcon = <PiFileCodeDuotone className='text-6xl' />  // Suponiendo que PiFileCodeDuotone representa un ícono XML
            }
            if (file.originFileObj.response) {
                fileTypeIcon = <MdErrorOutline className='text-6xl text-red-500' />
            }

            return (
                // <div className="custom-item-renderer">
                //     <img style={{ width: '64px', height: '40px' }} src={file.thumbUrl} alt={file.name} />
                //     <div className="buttons flex mt-1 ">
                //         <Button size="small" onClick={() => preview(
                //             setPreview({
                //                 isOpen: true, seeImage: file.thumbUrl, setTitle: file.name
                //             })
                //         )}>
                //             View
                //         </Button>
                //         <Button size="small" onClick={() => remove(file)}>
                //             Delete
                //         </Button>
                //     </div>
                // </div>


                // <Card
                //     style={{  marginTop: 16, display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}
                //     cover={fileTypeIcon}
                //     size='small'
                //     actions={[
                //         <div key='delete' className='flex gap-2 text-red-500 w-full items-center justify-center' onClick={() => remove(file)}>
                //             <p>Borrar</p>
                //             <MdOutlineDelete />
                //         </div>
                //     ]}
                // >
                //     <Meta title={<p className={file.originFileObj.response ? 'text-red-500' : 'text-black'}>{file.name}</p>} description={<p className='text-red-500'>{file.originFileObj.response}</p>} />
                //     {/* <Button type='primary' className='mt-4' block onClick={() => handleDownload(doc.url, doc.displayName)}>Descargar</Button> */}
                //     {/* <Button type='text' danger className='mt-2' block onClick={() => remove(file)}>Borrar</Button> */}
                // </Card>

                <div className='flex bg-white rounded-lg items-center gap-4 px-4 py-6 border-gray-200 border-2 mt-4 justify-between'>
                    <div className='flex gap-4 items-center'>
                        {fileTypeIcon}
                        <div className='flex flex-col gap-2'>
                            {<p className={(file.originFileObj.response ? 'text-red-500' : 'text-black') + ' font-medium text-lg'}>{file.name}</p>}
                            {<p className='text-red-500'>{file.originFileObj.response}</p>}
                        </div>
                    </div>
                    <Button type='primary' danger block onClick={() => remove(file)} className='max-w-52 items-center justify-center' size='large'>
                        <div className='flex items-center gap-2 justify-center'>
                            <MdOutlineDelete />
                            <p>Borrar</p>
                        </div>
                    </Button>
                </div>

            );
        }

        const props = {
            name: 'file',
            maxCount: 1,
            // listType: 'picture',
            itemRender: customItemRender,

            accept: 'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/xml,text/xml',
            // customRequest: handleUpload,
            beforeUpload(file: any) {
                if (file.size > 50 * 1024 * 1024) {
                    message.error('El archivo pesa más de 50mb');
                    file.response = 'El archivo pesa más de 50mb';
                    file.status = 'error'
                    return false
                }

                file.response = null

                const acceptedTypes = [
                    'image/*',
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/xml',
                    'text/xml',
                ];

                const isAccepted = acceptedTypes.some(type => {
                    if (type === 'image/*' && file.type.startsWith('image/')) {
                        // file.response = ''
                        return true;
                    }
                    return file.type === type;
                });

                if (!isAccepted) {
                    message.error('El formato de archivo no es aceptado');
                    file.response = 'El formato de archivo no es aceptado';
                    file.status = 'error'
                    return false
                }
                // console.log('file: ', file);
                return file

            },
            onChange(info: any) {

                // const { status } = info.file;
                // if (status !== 'uploading') {
                //     // console.log(info.file, info.fileList);
                //     if(status !== 'removed'){
                //         console.log('info.file.size: ', info.file.size /1024);
                //         if(info.file.size > 10){
                //             message.error('El archivo es demasiado grande');
                //         }
                //     }
                // }
                // if (status === 'done') {
                //     // message.success(`${info.file.name} file uploaded successfully.`);
                //     message.success(`File uploaded successfully.`);
                // } else if (status === 'error') {
                //     message.error(`${info.file.name} file upload failed.`);
                // }
            },
            onDrop(e: any) {
                console.log('Dropped files', e.dataTransfer.files);
            },
        };


        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    // label={<label className='font-medium text-sm'>{title}</label>} 
                    // label={<label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label>}
                    name={name}
                    rules={[{ required: required, message: 'Es obligatorio subir el documento' }]}
                    required={required}
                    // validateTrigger='onBlur'
                    valuePropName="copiaid"
                    getValueFromEvent={normFile}
                // validateDebounce={500}
                >
                    {/* <Upload action="/upload.do" listType="picture-card">
                        <button style={{ border: 0, background: 'none' }} type="button">
                            <FaPlus />
                            <div style={{ marginTop: 8 }}>Upload</div>
                        </button>
                    </Upload> */}
                    <Dragger {...props} disabled={disabled} >
                        <p className="flex justify-center text-2xl">
                            <FaPlus />
                        </p>
                        <p className="ant-upload-text">Da click o arrastra el archivo</p>
                        <p className="ant-upload-hint">Se permiten archivos de imagenes, PDFs y documentos de Office .</p>
                    </Dragger>
                </Form.Item>

            </div>
        )
    }
    if (type == "file_aviso") {

        const [fileList, setFileList] = useState<UploadFile[]>([]);
        const [uploading, setUploading] = useState(false);
        const [isFile, setIsFile] = useState(false)

        const { Dragger } = Upload;
        const normFile = (e: any) => {
            if (Array.isArray(e)) {
                return e;
            }
            return e?.fileList;
        };

        const [preview, setPreview] = useState({
            isOpen: false,
            seeImage: '',
            setTitle: ''
        });

        const customItemRender = (originNode: React.ReactElement, file: any, fileList: UploadFile[], { preview, remove }: any) => {
            // console.log('file: ', file);
            file.thumbUrl = URL.createObjectURL(file.originFileObj as RcFile)
            const { Meta } = Card;
            const fileType = file.type;
            let fileTypeIcon = null;
            if (fileType.startsWith('image/')) {
                fileTypeIcon = <img alt="example" src={file.thumbUrl} className='w-20 h-12 object-cover object-top rounded-md' />
            }
            if (fileType === 'application/pdf') {
                fileTypeIcon = <PiFilePdf className='text-4xl' />
            }
            if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                fileTypeIcon = <PiMicrosoftWordLogoFill className='text-4xl' />
            }
            if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                fileTypeIcon = <PiMicrosoftExcelLogoFill className='text-4xl' />
            }
            if (fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
                fileTypeIcon = <PiMicrosoftPowerpointLogoFill className='text-4xl' />
            }
            if (fileType === 'application/xml' || fileType === 'text/xml') {
                fileTypeIcon = <PiFileCodeDuotone className='text-4xl' />  // Suponiendo que PiFileCodeDuotone representa un ícono XML
            }
            if (file.originFileObj.response) {
                fileTypeIcon = <MdErrorOutline className='text-4xl text-red-500' />
            }

            return (
                <div className='flex bg-white rounded-lg items-center gap-4 px-4 py-6 border-gray-200 border-2 mt-4 justify-between'>
                    <div className='flex gap-4 items-center'>
                        {fileTypeIcon}
                        <div className='flex flex-col gap-2'>
                            {<p className={(file.originFileObj.response ? 'text-red-500' : 'text-black') + ' font-medium text-sm'}>{file.name}</p>}
                            {<p className='text-red-500'>{file.originFileObj.response}</p>}
                        </div>
                    </div>
                    <Button type='primary' danger onClick={() => remove(file)} className='max-w-52 items-center justify-center' size='middle'>
                        <div className='flex items-center gap-2 justify-center'>
                            <MdOutlineDelete />
                            {/* <p>Borrar</p> */}
                        </div>
                    </Button>
                </div>

            );
        }

        const props = {
            name: 'file',
            maxCount: 1,
            // listType: 'picture',
            itemRender: customItemRender,

            accept: 'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/xml,text/xml',
            // customRequest: handleUpload,
            beforeUpload(file: any) {
                if (file.size > 50 * 1024 * 1024) {
                    message.error('El archivo pesa más de 50mb');
                    file.response = 'El archivo pesa más de 50mb';
                    file.status = 'error'
                    return false
                }

                file.response = null

                const acceptedTypes = [
                    'image/*',
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/xml',
                    'text/xml',
                ];

                const isAccepted = acceptedTypes.some(type => {
                    if (type === 'image/*' && file.type.startsWith('image/')) {
                        // file.response = ''
                        return true;
                    }
                    return file.type === type;
                });

                if (!isAccepted) {
                    message.error('El formato de archivo no es aceptado');
                    file.response = 'El formato de archivo no es aceptado';
                    file.status = 'error'
                    return false
                }
                // console.log('file: ', file);
                return file

            },
            onChange(info: any) {

                if(info.fileList.length > 0){
                    setIsFile(true)
                } else {
                    setIsFile(false)
                }

                // console.log('info: ', info);
            },
            onDrop(e: any) {
                console.log('Dropped files', e.dataTransfer.files);
            },
        };


        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    // label={<label className='font-medium text-sm'>{title}</label>} 
                    label={<label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label>}
                    name={name}
                    rules={[{ required: required, message: 'Es obligatorio subir el documento' }]}
                    required={required}
                    // validateTrigger='onBlur'
                    valuePropName="copiaid"
                    getValueFromEvent={normFile}
                // style={{ width: '100%', display: 'flex'}}
                // validateDebounce={500}
                >
                    {/* <Dragger {...props} disabled={disabled} style={{display: 'hidden'}}>
                        <p className="flex justify-center text-2xl">
                            <FaPlus />
                        </p>
                        <p className="ant-upload-text">Da click o arrastra el archivo</p>
                        <p className="ant-upload-hint">Se permiten archivos de imagenes, PDFs y documentos de Office .</p>
                    </Dragger> */}
                    <Upload {...props} disabled={disabled}>
                        {!isFile &&
                            <Button icon={<UploadOutlined />}>Cargar Archivo</Button>
                        }
                    </Upload>
                </Form.Item>

            </div>
        )
    }
    if (type == "file_anexo") {

        const [fileList, setFileList] = useState<UploadFile[]>([]);
        const [uploading, setUploading] = useState(false);
        const [isFile, setIsFile] = useState(false)

        const { Dragger } = Upload;
        const normFile = (e: any) => {
            if (Array.isArray(e)) {
                return e;
            }
            return e?.fileList;
        };

        const [preview, setPreview] = useState({
            isOpen: false,
            seeImage: '',
            setTitle: ''
        });

        const customItemRender = (originNode: React.ReactElement, file: any, fileList: UploadFile[], { preview, remove }: any) => {
            // console.log('file: ', file);
            file.thumbUrl = URL.createObjectURL(file.originFileObj as RcFile)
            const { Meta } = Card;
            const fileType = file.type;
            let fileTypeIcon = null;
            if (fileType.startsWith('image/')) {
                fileTypeIcon = <img alt="example" src={file.thumbUrl} className='w-20 h-12 object-cover object-top rounded-md' />
            }
            if (fileType === 'application/pdf') {
                fileTypeIcon = <PiFilePdf className='text-4xl' />
            }
            if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                fileTypeIcon = <PiMicrosoftWordLogoFill className='text-4xl' />
            }
            if (fileType === 'application/vnd.ms-excel' || fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                fileTypeIcon = <PiMicrosoftExcelLogoFill className='text-4xl' />
            }
            if (fileType === 'application/vnd.ms-powerpoint' || fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
                fileTypeIcon = <PiMicrosoftPowerpointLogoFill className='text-4xl' />
            }
            if (fileType === 'application/xml' || fileType === 'text/xml') {
                fileTypeIcon = <PiFileCodeDuotone className='text-4xl' />  // Suponiendo que PiFileCodeDuotone representa un ícono XML
            }
            if (file.originFileObj.response) {
                fileTypeIcon = <MdErrorOutline className='text-4xl text-red-500' />
            }

            return (
                <div className='flex bg-white rounded-lg items-center gap-4 px-4 py-6 border-gray-200 border-2 mt-4 justify-between'>
                    <div className='flex gap-4 items-center'>
                        {fileTypeIcon}
                        <div className='flex flex-col gap-2'>
                            {<p className={(file.originFileObj.response ? 'text-red-500' : 'text-black') + ' font-medium text-sm'}>{file.name}</p>}
                            {<p className='text-red-500'>{file.originFileObj.response}</p>}
                        </div>
                    </div>
                    <Button type='primary' danger onClick={() => remove(file)} className='max-w-52 items-center justify-center' size='middle'>
                        <div className='flex items-center gap-2 justify-center'>
                            <MdOutlineDelete />
                            <p>Borrar</p>
                        </div>
                    </Button>
                </div>

            );
        }

        const props = {
            name: 'file',
            maxCount: 1,
            // listType: 'picture',
            itemRender: customItemRender,

            accept: 'image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/xml,text/xml',
            // customRequest: handleUpload,
            beforeUpload(file: any) {
                if (file.size > 50 * 1024 * 1024) {
                    message.error('El archivo pesa más de 50mb');
                    file.response = 'El archivo pesa más de 50mb';
                    file.status = 'error'
                    return false
                }

                file.response = null

                const acceptedTypes = [
                    'image/*',
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/xml',
                    'text/xml',
                ];

                const isAccepted = acceptedTypes.some(type => {
                    if (type === 'image/*' && file.type.startsWith('image/')) {
                        // file.response = ''
                        return true;
                    }
                    return file.type === type;
                });

                if (!isAccepted) {
                    message.error('El formato de archivo no es aceptado');
                    file.response = 'El formato de archivo no es aceptado';
                    file.status = 'error'
                    return false
                }
                // console.log('file: ', file);
                return file

            },
            onChange(info: any) {

                if(info.fileList.length > 0){
                    setIsFile(true)
                } else {
                    setIsFile(false)
                }

                // console.log('info: ', info);
            },
            onDrop(e: any) {
                console.log('Dropped files', e.dataTransfer.files);
            },
        };

        const getParentValue = (value: any) => {
            console.log('parent_value: ', parent_value);
        }


        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    // label={<label className='font-medium text-sm'>{title}</label>} 
                    label={title &&<label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label>}
                    name={name}
                    rules={[{ required: required, message: 'Es obligatorio subir el documento' }]}
                    required={required}
                    // validateTrigger='onBlur'
                    valuePropName="copiaid"
                    getValueFromEvent={normFile}
                // style={{ width: '100%', display: 'flex'}}
                // validateDebounce={500}
                >
                    {/* <Dragger {...props} disabled={disabled} style={{display: 'hidden'}}>
                        <p className="flex justify-center text-2xl">
                            <FaPlus />
                        </p>
                        <p className="ant-upload-text">Da click o arrastra el archivo</p>
                        <p className="ant-upload-hint">Se permiten archivos de imagenes, PDFs y documentos de Office .</p>
                    </Dragger> */}
                    <Upload {...props} disabled={disabled} onChange={onChangeFunc}>
                        {!isFile &&
                            <Button icon={<UploadOutlined />}>Cargar Archivo</Button>
                        }
                    </Upload>
                </Form.Item>

            </div>
        )
    }
    if (type == "password") {
        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                    // label={title}
                    name={name}
                    rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'string', message: 'Datos inválidos' }]}
                    required={required}
                    validateTrigger='onBlur'
                // validateDebounce={500}
                >
                    <Input.Password
                        id={id}
                        placeholder={placeholder}
                        disabled={disabled}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
            </div>
        )
    }

    if (type == "number") {
        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                    // label={title}
                    name={name}
                    rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'number', message: 'Ingresa un valor numérico' }]}
                    required={required}
                    validateTrigger='onBlur'
                    style={{ marginBottom: required ? '24px' : '0' }}
                // validateDebounce={500}
                >
                    <InputNumber placeholder={placeholder} min={0} style={{ width: '100%' }} disabled={disabled} id={id}/>
                </Form.Item>
            </div>
        )
    }

    if (type == "select") {

        const filterOption = (input: string, option?: { label: string; value: string }) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

        const onChange = (value: string) => {
            // console.log(`selected ${value}`);
        };

        return (
            <div className='flex-grow'>
                <Form.Item
                    hasFeedback={hideFeedback ? false : true}
                    // label={<label className='font-medium text-sm'>{title}</label>} 
                    label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                    name={name}
                    rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'string', message: 'Datos inválidos' }]}
                    // rules={[{ required: required, message: 'Este campo es obligatorio' } ]}
                    // rules= {[{ type: 'object' as const, required: true, message: 'Please select time!' }]}
                    validateTrigger='onBlur'
                    initialValue={default_value}
                    id={id}
                // initialValue={'mexico'}
                // validateDebounce={500}
                >
                    <Select
                        showSearch={true}
                        className='w-full'
                        placeholder={placeholder}
                        optionFilterProp="children"
                        onChange={onChange}
                        // onSearch={onSearch}
                        filterOption={filterOption}
                        options={select_options ? select_options : []}
                        disabled={disabled}
                        id={id}
                    />
                </Form.Item>
            </div>
        )
    }

    if (type == "radio_anexo") {

        // console.log('option: ', option);
        const [radioValue, setRadioValue] = useState(option ?? 'no')

        const handleChange = (e: any) => {
            // console.log(e.target.value)
            setRadioValue(e.target.value)
        }

        // console.log('radioValue: ', radioValue, name);


        return (
            <div className='flex flex-col flex-grow'>
                <div className='flex-grow'>
                    <Form.Item
                        hasFeedback={hideFeedback ? false : true}
                        // label={<label className='font-medium text-sm'>{title}</label>} 
                        label={title != '' ? <label htmlFor={formName + '_' + name} className='font-medium text-sm'>{title}</label> : false}
                        name={name}
                        rules={[{ required: required, message: 'Este campo es obligatorio' }, { type: 'string', message: 'Datos Inválidos' }]}
                        required={required}
                        validateTrigger='onBlur'
                        id={id}
                    // initialValue={'no'}
                    // validateDebounce={500}
                    >
                        <Radio.Group onChange={handleChange} disabled={disabled} style={{ display: 'flex', flexDirection: 'column', gap: '8px'
                         }}>
                            <Radio value="no"> No </Radio>
                            <Radio value="sif"> Si y se guardan en físico </Radio>
                            <Radio value="sid"> Si y se guardan en digital </Radio>
                            <Radio value="sidf"> Si y se guardan en digital y físico</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
                {(radioValue == 'sidf' || radioValue == 'sid' || radioValue == 'si') && children}
            </div>
        )
    }
}

export default AntInput